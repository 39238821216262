import { Controller } from '@hotwired/stimulus';

import { formToQueryString } from '../../utils';

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', () => {
      Array.from(this.element.children).forEach((child) => child.classList.add('is-loading'));

      const loader = document.createElement('div');
      loader.id = 'loader';

      this.element.appendChild(loader);
    });
  }

  change(event) {
    if (this.element.getAttribute('data-remote') === 'true') {
      this.element.dispatchEvent(new Event('submit', { bubbles: true }));

      const additionalParams = {};

      // handle submit buttons that have names and values
      if (event.target.nodeName === 'BUTTON' && event.target.name && typeof event.target.value !== 'undefined') {
        additionalParams[event.target.name] = event.target.value;
      }

      window.history.pushState(
        { turbolinks: {} },
        '',
        `${window.location.pathname}?${formToQueryString(this.element, additionalParams)}`,
      );
    } else {
      this.element.submit();
    }
  }
}
